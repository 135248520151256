var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-6"
  }, [_c('FormGroup', {
    attrs: {
      "id": "startDate-modal",
      "value": _vm.value.startDate,
      "label": "Start Date",
      "type": "datePicker"
    },
    on: {
      "input": function input(val) {
        return _vm.onInput(val, 'startDate');
      }
    }
  })], 1), _c('div', {
    staticClass: "col-md-6"
  }, [_c('FormGroup', {
    attrs: {
      "id": "endDate-modal",
      "label": "End Date",
      "type": "datePicker"
    },
    on: {
      "input": function input(val) {
        return _vm.onInput(val, 'endDate');
      }
    }
  })], 1)]), _c('FormGroup', {
    attrs: {
      "id": "rateName-modal",
      "value": _vm.value.rateName,
      "label": "Rate Name",
      "type": "text"
    },
    on: {
      "input": function input(val) {
        return _vm.onInput(val, 'rateName');
      }
    }
  }), _c('FormGroup', {
    attrs: {
      "id": "type-modal",
      "value": _vm.value.type,
      "label": "Rate Type",
      "type": "select",
      "options": _vm.rateTypes
    },
    on: {
      "input": function input(val) {
        return _vm.onInput(val, 'type');
      }
    }
  }), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-4"
  }, [_c('FormGroup', {
    attrs: {
      "id": "consumption-modal",
      "value": _vm.value.consumption,
      "label": "Consumption",
      "type": "text"
    },
    on: {
      "input": function input(val) {
        return _vm.onInput(val, 'consumption');
      }
    }
  })], 1), _c('div', {
    staticClass: "col-md-4"
  }, [_c('FormGroup', {
    attrs: {
      "id": "unitRate-modal",
      "value": _vm.value.unitRate,
      "label": "Unit Rate",
      "type": "text"
    },
    on: {
      "input": function input(val) {
        return _vm.onInput(val, 'unitRate');
      }
    }
  })], 1), _c('div', {
    staticClass: "col-md-4"
  }, [_c('FormGroup', {
    attrs: {
      "id": "cost-modal",
      "value": _vm.value.cost,
      "label": "Cost",
      "type": "text"
    },
    on: {
      "input": function input(val) {
        return _vm.onInput(val, 'cost');
      }
    }
  })], 1)]), _c('div', {
    staticClass: "form-group"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    class: _vm.displayRegister ? 'col-md-6' : 'col-md-9'
  }, [_c('label', [_vm._v("Start Read")])]), _vm._m(0), _vm.displayRegister ? _c('div', {
    staticClass: "col-md-3"
  }, [_c('label', [_vm._v("Register")])]) : _vm._e()]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    class: _vm.displayRegister ? 'col-md-6' : 'col-md-9'
  }, [_c('div', {
    staticClass: "form-group"
  }, [_c('div', {
    staticClass: "input-group"
  }, [_c('FormItem', {
    attrs: {
      "id": "startRead-modal",
      "value": _vm.value.startRead,
      "type": "text",
      "placeholder": "Value"
    },
    on: {
      "input": function input(val) {
        return _vm.onInput(val, 'startRead');
      }
    }
  }), _c('div', {
    staticClass: "input-group-append mr-0 pr-0",
    class: _vm.displayRegister ? '' : ''
  }, [_c('FormItem', {
    style: {
      width: _vm.displayRegister ? '200px' : '250px'
    },
    attrs: {
      "id": "startReadUnits-modal",
      "options": _vm.unitOptions,
      "type": "select",
      "is-alt": true
    },
    model: {
      value: _vm.value.startReadUnit,
      callback: function callback($$v) {
        _vm.$set(_vm.value, "startReadUnit", $$v);
      },
      expression: "value.startReadUnit"
    }
  })], 1)], 1)])]), _c('div', {
    staticClass: "col-md-3"
  }, [_c('FormItem', {
    attrs: {
      "id": "startReadType-modal",
      "value": _vm.value.startReadType,
      "type": "text",
      "placeholder": "Type"
    },
    on: {
      "input": function input(val) {
        return _vm.onInput(val, 'startReadType');
      }
    }
  })], 1), _vm.displayRegister ? _c('div', {
    staticClass: "col-md-3"
  }, [_c('FormItem', {
    attrs: {
      "id": "startReadRegister-modal",
      "value": _vm.value.startReadRegister,
      "type": "select",
      "placeholder": "Register",
      "options": _vm.registerIds
    },
    on: {
      "input": function input(val) {
        return _vm.onInput(val, 'startReadRegister');
      }
    }
  })], 1) : _vm._e()])]), _c('div', {
    staticClass: "form-group"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    class: _vm.displayRegister ? 'col-md-6' : 'col-md-9'
  }, [_c('label', [_vm._v("End Read")])]), _vm._m(1), _vm.displayRegister ? _c('div', {
    staticClass: "col-md-3"
  }, [_c('label', [_vm._v("Register")])]) : _vm._e()]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    class: _vm.displayRegister ? 'col-md-6' : 'col-md-9'
  }, [_c('div', {
    staticClass: "form-group"
  }, [_c('div', {
    staticClass: "input-group"
  }, [_c('FormItem', {
    attrs: {
      "id": "endRead-modal",
      "value": _vm.value.endRead,
      "type": "text",
      "placeholder": "Value"
    },
    on: {
      "input": function input(val) {
        return _vm.onInput(val, 'endRead');
      }
    }
  }), _c('div', {
    staticClass: "input-group-append"
  }, [_c('FormItem', {
    style: {
      width: _vm.displayRegister ? '200px' : '250px'
    },
    attrs: {
      "id": "endReadUnits-modal",
      "options": _vm.unitOptions,
      "type": "select",
      "is-alt": true
    },
    model: {
      value: _vm.value.endReadUnit,
      callback: function callback($$v) {
        _vm.$set(_vm.value, "endReadUnit", $$v);
      },
      expression: "value.endReadUnit"
    }
  })], 1)], 1)])]), _c('div', {
    staticClass: "col-md-3"
  }, [_c('FormItem', {
    attrs: {
      "id": "endReadType-modal",
      "value": _vm.value.endReadType,
      "type": "text",
      "placeholder": "Type"
    },
    on: {
      "input": function input(val) {
        return _vm.onInput(val, 'endReadType');
      }
    }
  })], 1), _vm.displayRegister ? _c('div', {
    staticClass: "col-md-3"
  }, [_c('FormItem', {
    attrs: {
      "id": "endReadRegister-modal",
      "value": _vm.value.endReadRegister,
      "type": "select",
      "placeholder": "Register",
      "options": _vm.registerIds
    },
    on: {
      "input": function input(val) {
        return _vm.onInput(val, 'endReadRegister');
      }
    }
  })], 1) : _vm._e()])])], 1);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "col-md-3"
  }, [_c('label', [_vm._v("Type")])]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "col-md-3"
  }, [_c('label', [_vm._v("Type")])]);
}]

export { render, staticRenderFns }