<template>
  <div>
    <div v-if="existingFileName" class="d-flex align-items-center flex-grow-1 p-3 FileUpload FileUpload-readonly">
      <i :class="`fad fa-fw fa-2x mr-3 ${status.icon}`"></i>
      <div class="text-break flex-grow-1 font-w600 mb-0">
        <span :class="status.class">{{ status.text }}</span>
      </div>
      <button v-if="!readOnly" class="btn btn-link text-danger fa-xl" @click.prevent="reset">
        <i class="fa fa-times me-1" aria-hidden="true"></i>
      </button>
    </div>
    <div v-else>
      <div
        class="d-flex align-items-center flex-grow-1 p-3 FileUpload"
        :class="{ 'FileUpload-drag': isDrag }"
        @click="$refs.fileInput.click()"
        @drop.prevent="onFileDrop"
        @dragenter.prevent="onFileStartDrag"
        @dragover.prevent="onFileStartDrag"
        @dragleave.prevent="onFileStopDrag"
      >
        <input
          :id="name"
          ref="fileInput"
          type="file"
          :name="name"
          :accept="fileTypes?.length > 0 ? fileTypes.map(type => `.${type}`).join(', ') : null"
          enctype="multipart/form-data"
          style="display: none"
          :multiple="multipleFiles"
          @change="e => onFileSelect(e.target)"
        />
        <i :class="`fad fa-fw fa-2x mr-3 ${status.icon}`"></i>
        <div class="text-break flex-grow-1 font-w600 mb-0">
          <span v-if="isDrag">Drop file here</span>
          <span v-else :class="status.class">{{ status.text }}</span>
        </div>
        <div v-if="progress !== null && loading" class="FileUpload-progress">
          <div
            class="FileUpload-progress-bar"
            :class="{ 'FileUpload-progress-bar--complete': progress === 100 }"
            :style="`width: ${progress}%`"
          ></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'FileUpload',
  props: {
    loading: {
      required: false,
      type: Boolean
    },
    value: {
      required: false,
      type: Object,
      default: null
    },
    fileTypes: {
      required: false,
      type: Array,
      default: () => ['pdf']
    },
    existingFileName: {
      required: false,
      type: String,
      default: null
    },
    readOnly: {
      required: false,
      type: Boolean,
      default: false
    },
    progress: {
      required: false,
      type: [Number, null],
      default: () => null
    },
    error: {
      required: false,
      type: [String, null],
      default: () => null
    },
    bgColour: {
      validator(value) {
        return ['grey', 'white'].includes(value);
      },
      type: String,
      required: false,
      default: 'grey'
    },
    multipleFiles: {
      type: Boolean,
      required: false,
      default: false
    },
    name: {
      type: String,
      required: false,
      default: 'file'
    }
  },
  data() {
    return {
      deleteFile: false,
      currentFileName: '',
      uploadFileName: null,
      isDrag: false,
      dragTimeout: null
    };
  },
  computed: {
    status() {
      // Stages
      // 1. Pending - No file selected - Default state
      // 2. Selected - File selected
      // 3. Uploading - File uploading
      // 4. Uploaded - File uploaded
      // 5. Error - Error uploading file
      // 6. Existing - Existing file

      const selected = this.uploadFileName !== null;
      const existing = this.existingFileName !== null && this.existingFileName !== '';
      const uploading = !!this.loading && this.progress !== 100;
      const uploaded = !this.loading && this.progress === 100;
      const error = !!this.error;

      if (error) {
        return {
          status: 'error',
          icon: 'fa-file-circle-xmark text-danger',
          text: this.error,
          class: 'text-danger'
        };
      } else if (uploaded) {
        return {
          status: 'uploaded',
          icon: 'fa-file-circle-check text-success',
          text: this.uploadFileName
        };
      } else if (uploading) {
        return {
          status: 'uploading',
          icon: 'fa-spinner fa-spin text-muted',
          text: 'Uploading...'
        };
      } else if (existing) {
        return {
          status: 'selected',
          icon: 'fa-file text-success',
          text: this.existingFileName
        };
      } else if (selected) {
        return {
          status: 'selected',
          icon: 'fa-file-import text-warning',
          text: this.uploadFileName
        };
      } else {
        return {
          status: 'pending',
          icon: 'fa-file text-secondary',
          text: 'Drop a file or click here to upload'
        };
      }
    }
  },
  methods: {
    onFileSelect({ files }, fileDrop) {
      this.reset();

      if (this.multipleFiles) return this.handleMultipleFiles(files, fileDrop);

      this.handleSingleFile(files);
    },
    handleSingleFile(files) {
      const formData = new FormData();
      formData.append(this.name, files[0], files[0].name);

      this.$emit('input', {
        file: formData,
        fileName: files[0].name
      });

      this.uploadFileName = files[0].name;
    },
    handleMultipleFiles(files, fileDrop) {
      const currentFormData = this.value?.files;
      const formToAppend = !fileDrop || !currentFormData ? new FormData() : currentFormData;

      Array.from(Array(files.length).keys()).forEach(x => {
        formToAppend.append(this.name, files[x], files[x].name);
      });

      this.$emit('input', {
        files: formToAppend
      });

      const numberOfFiles = Array.from(formToAppend.keys()).length;
      this.uploadFileName = numberOfFiles > 1 ? `${numberOfFiles} Files` : files[0].name;
    },
    onFileDrop(e) {
      this.isDrag = false;
      this.onFileSelect(e.dataTransfer, true);
    },
    onFileStartDrag() {
      this.isDrag = true;
      clearTimeout(this.dragTimeout);
    },
    onFileStopDrag() {
      this.dragTimeout = setTimeout(() => {
        this.isDrag = false;
      }, 50);
    },
    reset() {
      this.$emit('reset');
      this.uploadFileName = null;
    }
  }
};
</script>
<style>
.FileUpload {
  position: relative;
  border: 2px solid transparent;
  cursor: pointer;
  border-radius: 0.25rem;
}
.FileUpload-readonly {
  cursor: default;
}
.FileUpload-drag {
  border: 2px dashed #a1a1a1;
}
.FileUpload-progress {
  position: absolute;
  height: 5px;
  width: calc(100% + 4px);
  border-radius: 0.25rem;
  bottom: -2px;
  left: -2px;
  right: -2px;
  background-color: #ddd;
}
.FileUpload-progress-bar {
  position: absolute;
  height: 5px;
  border-radius: 0.25rem;
  background-color: #ffd000;
}
.FileUpload-progress-bar--complete {
  background-color: #82b54b;
}
</style>
