// Calculates various totals based on the provided rates
// `subKey` specifies a nested property in rate objects from which values should be extracted.
const calculateTotals = (rates, form, subKey) => {
  // `precision` specifies the number of decimal places for the result,
  // `conditional` indicates if the calculation should only occur under certain conditions.
  const config = {
    totalUnitCost: { type: 'unitRate', key: 'cost', precision: 3 },
    totalDaily: { type: 'daily', key: 'cost', precision: 2 },
    totalLevy: { type: 'levy', key: 'cost', precision: 2 },
    totalKva: { type: 'kva', key: 'cost', precision: 2 },
    totalUnits: { type: 'unitRate', key: 'consumption', precision: 2 },
    totalWaterVolume: { type: 'waterRate', key: 'consumption', precision: 3, conditional: true },
    totalWasteVolume: { type: 'wasteRate', key: 'consumption', precision: 3, conditional: true },
    totalWaterCost: { type: 'waterRate', key: 'cost', precision: 2, conditional: true },
    totalWasteCost: { type: 'wasteRate', key: 'cost', precision: 2, conditional: true },
    totalTax: { type: 'tax', key: 'cost', precision: 2 }
  };

  // Calculate totals based on the configuration.
  const totals = Object.entries(config).reduce((results, [totalName, { type, key, precision, conditional }]) => {
    if (!conditional || (conditional && form[totalName])) {
      results[totalName] = rates
        .filter(rate => rate.type === type)
        .reduce((sum, rate) => sum + parseFloat((subKey ? rate[subKey][key] : rate[key]) || 0), 0)
        .toFixed(precision);
    }
    return results;
  }, {});

  totals.netTotalCost = rates
    .filter(rate => ['unitRate', 'kva', 'levy', 'daily', 'waterRate', 'wasteRate'].includes(rate.type))
    .reduce((sum, rate) => sum + parseFloat((subKey ? rate[subKey].cost : rate.cost) || 0), 0)
    .toFixed(2);

  totals.totalCost = parseFloat(totals.netTotalCost) + parseFloat(totals.totalTax);

  return totals;
};

const rateTypes = [
  { label: 'Unit Rate (p/kWh)', value: 'unitRate' },
  { label: 'Daily (£/day)', value: 'daily' },
  { label: 'Levy (p/kWh)', value: 'levy' },
  { label: 'VAT (%)', value: 'tax' },
  { label: 'Availability (kVA)', value: 'kva' },
  { label: 'Water (£/m3)', value: 'waterRate' },
  { label: 'Waste (£/m3)', value: 'wasteRate' }
];

const invoiceTotalKeys = ['totalUnitCost', 'totalDaily', 'totalLevy', 'totalKva', 'totalUnits', 'netTotalCost', 'totalTax', 'totalCost'];

export { calculateTotals, invoiceTotalKeys, rateTypes };
