<template>
  <div>
    <div class="row">
      <div class="col-md-6">
        <FormGroup id="startDate-modal" :value="value.startDate" label="Start Date" type="datePicker" @input="val => onInput(val, 'startDate')" />
      </div>
      <div class="col-md-6">
        <FormGroup id="endDate-modal" label="End Date" type="datePicker" @input="val => onInput(val, 'endDate')" />
      </div>
    </div>
    <FormGroup id="rateName-modal" :value="value.rateName" label="Rate Name" type="text" @input="val => onInput(val, 'rateName')" />
    <FormGroup id="type-modal" :value="value.type" label="Rate Type" type="select" :options="rateTypes" @input="val => onInput(val, 'type')" />
    <div class="row">
      <div class="col-md-4">
        <FormGroup id="consumption-modal" :value="value.consumption" label="Consumption" type="text" @input="val => onInput(val, 'consumption')" />
      </div>
      <div class="col-md-4">
        <FormGroup id="unitRate-modal" :value="value.unitRate" label="Unit Rate" type="text" @input="val => onInput(val, 'unitRate')" />
      </div>
      <div class="col-md-4">
        <FormGroup id="cost-modal" :value="value.cost" label="Cost" type="text" @input="val => onInput(val, 'cost')" />
      </div>
    </div>
    <div class="form-group">
      <div class="row">
        <div :class="displayRegister ? 'col-md-6' : 'col-md-9'">
          <label>Start Read</label>
        </div>
        <div class="col-md-3">
          <label>Type</label>
        </div>
        <div v-if="displayRegister" class="col-md-3">
          <label>Register</label>
        </div>
      </div>
      <div class="row">
        <div :class="displayRegister ? 'col-md-6' : 'col-md-9'">
          <div class="form-group">
            <div class="input-group">
              <FormItem id="startRead-modal" :value="value.startRead" type="text" placeholder="Value" @input="val => onInput(val, 'startRead')" />
              <div class="input-group-append mr-0 pr-0" :class="displayRegister ? '' : ''">
                <FormItem
                  :id="`startReadUnits-modal`"
                  v-model="value.startReadUnit"
                  :options="unitOptions"
                  type="select"
                  :is-alt="true"
                  :style="{ width: displayRegister ? '200px' : '250px' }"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-3">
          <FormItem
            id="startReadType-modal"
            :value="value.startReadType"
            type="text"
            placeholder="Type"
            @input="val => onInput(val, 'startReadType')"
          />
        </div>
        <div v-if="displayRegister" class="col-md-3">
          <FormItem
            id="startReadRegister-modal"
            :value="value.startReadRegister"
            type="select"
            placeholder="Register"
            :options="registerIds"
            @input="val => onInput(val, 'startReadRegister')"
          />
        </div>
      </div>
    </div>
    <div class="form-group">
      <div class="row">
        <div :class="displayRegister ? 'col-md-6' : 'col-md-9'">
          <label>End Read</label>
        </div>
        <div class="col-md-3">
          <label>Type</label>
        </div>
        <div v-if="displayRegister" class="col-md-3">
          <label>Register</label>
        </div>
      </div>
      <div class="row">
        <div :class="displayRegister ? 'col-md-6' : 'col-md-9'">
          <div class="form-group">
            <div class="input-group">
              <FormItem id="endRead-modal" :value="value.endRead" type="text" placeholder="Value" @input="val => onInput(val, 'endRead')" />
              <div class="input-group-append">
                <FormItem
                  :id="`endReadUnits-modal`"
                  v-model="value.endReadUnit"
                  :options="unitOptions"
                  type="select"
                  :is-alt="true"
                  :style="{ width: displayRegister ? '200px' : '250px' }"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-3">
          <FormItem id="endReadType-modal" :value="value.endReadType" type="text" placeholder="Type" @input="val => onInput(val, 'endReadType')" />
        </div>
        <div v-if="displayRegister" class="col-md-3">
          <FormItem
            id="endReadRegister-modal"
            :value="value.endReadRegister"
            type="select"
            placeholder="Register"
            :options="registerIds"
            @input="val => onInput(val, 'endReadRegister')"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { rateTypes } from '../lib/invoice';

import FormItem from '@/components/FormItem';
import FormGroup from '@/components/FormGroup';

export default {
  name: 'AddRateForm',
  components: {
    FormItem,
    FormGroup
  },
  props: {
    value: {
      type: null,
      default: ''
    },
    registerIds: {
      type: Array,
      default: () => []
    },
    unitOptions: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      rateTypes: [...rateTypes]
    };
  },
  computed: {
    displayRegister() {
      return this.registerIds.length > 1;
    }
  },
  methods: {
    onInput(val, prop) {
      const newValue = { ...this.value };
      newValue[prop] = val;
      this.$emit('input', newValue);
    }
  }
};
</script>
