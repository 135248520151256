var render = function () {
  var _vm$sortedSuppliers$f;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [!_vm.loading.results && _vm.invoice._id ? _c('div', {
    key: _vm.invoice._id
  }, [_c('ul', {
    staticClass: "nav nav-tabs nav-tabs-alt",
    attrs: {
      "role": "tablist"
    }
  }, _vm._l(_vm.tabs, function (tab, index) {
    return _c('li', {
      key: index,
      staticClass: "nav-item"
    }, [_c('a', {
      staticClass: "nav-link",
      class: {
        active: _vm.activeTab === tab.value
      },
      attrs: {
        "active-class": "active",
        "href": "#"
      },
      on: {
        "click": function click($event) {
          $event.preventDefault();
          _vm.activeTab = tab.value;
        }
      }
    }, [_c('i', {
      staticClass: "mr-1",
      class: tab.icon
    }), _vm._v(" " + _vm._s(tab.label) + " ")])]);
  }), 0), _vm.activeTab === 'details' ? _c('div', [_c('div', {
    staticClass: "row no-gutters pb-2 mb-3 mt-3 align-items-end"
  }, [_vm._m(0), _c('div', {
    staticClass: "text-right"
  }, [!_vm.editMode ? _c('button', {
    staticClass: "btn btn-primary btn-sm",
    on: {
      "click": function click($event) {
        _vm.modals.edit = true;
      }
    }
  }, [_c('i', {
    staticClass: "fa fa-pencil mr-2"
  }), _vm._v("Edit")]) : _c('button', {
    staticClass: "btn btn-link text-danger fa-lg",
    on: {
      "click": _vm.onClickCancel
    }
  }, [_c('i', {
    staticClass: "fa fa-times danger"
  })])])]), _c('div', {
    staticClass: "row mb-3"
  }, [_c('div', {
    staticClass: "col-md-6"
  }, [_c('PropertyListItem', {
    staticClass: "d-flex align-items-center mb-2",
    attrs: {
      "title": "Simulated Data",
      "border": !!_vm.editMode
    }
  }, [_vm.editMode ? _c('FormItem', {
    attrs: {
      "id": "simulated",
      "type": "select",
      "options": [{
        label: 'No',
        value: false
      }, {
        label: 'Yes',
        value: true
      }]
    },
    model: {
      value: _vm.form.simulated,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "simulated", $$v);
      },
      expression: "form.simulated"
    }
  }) : _c('span', [_vm._v(" " + _vm._s(_vm.form.simulated ? 'Yes' : 'No') + " ")])], 1), _c('PropertyListItem', {
    staticClass: "d-flex align-items-center mb-2",
    attrs: {
      "title": "Supplier",
      "border": !!_vm.editMode
    }
  }, [_vm.editMode ? _c('FormItem', {
    attrs: {
      "id": "supplierId",
      "options": _vm.sortedSuppliers.map(function (s) {
        return {
          label: s.name,
          value: s._id
        };
      }),
      "label": "Supplier Name",
      "type": "select",
      "config": {
        allowSearch: true
      }
    },
    model: {
      value: _vm.form.supplierId,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "supplierId", $$v);
      },
      expression: "form.supplierId"
    }
  }) : _c('span', [_vm._v(" " + _vm._s(((_vm$sortedSuppliers$f = _vm.sortedSuppliers.find(function (s) {
    return s._id === _vm.form.supplierId;
  })) === null || _vm$sortedSuppliers$f === void 0 ? void 0 : _vm$sortedSuppliers$f.name) || 'N/A') + " ")])], 1), _vm._l(_vm.invoiceValues.slice(0, Math.floor(_vm.invoiceValues.length / 2)), function (value) {
    return _c('PropertyListItem', {
      key: value.key,
      staticClass: "d-flex align-items-center mb-2",
      attrs: {
        "title": value.friendlyKey,
        "border": !!_vm.editMode
      }
    }, [_vm.editMode ? _c('span', [_c('FormItem', {
      attrs: {
        "id": value.key,
        "type": value.formType,
        "value": value.friendlyValue
      },
      on: {
        "input": function input(_input) {
          return _vm.onChangeValue(_input, value.key);
        }
      }
    })], 1) : _c('span', [_vm._v(" " + _vm._s(_vm._f("round")(value.friendlyValue, 2)) + " ")])]);
  })], 2), _c('div', {
    staticClass: "col-md-6"
  }, [_vm._l(_vm.invoiceValues.slice(Math.floor(_vm.invoiceValues.length / 2), _vm.invoiceValues.length), function (value) {
    return _c('PropertyListItem', {
      key: value.key,
      staticClass: "d-flex align-items-center mb-2",
      attrs: {
        "title": value.friendlyKey,
        "border": !!_vm.editMode
      }
    }, [_vm.editMode ? _c('span', [_c('FormItem', {
      attrs: {
        "id": value.key,
        "type": value.formType,
        "value": value.friendlyValue
      },
      on: {
        "input": function input(_input2) {
          return _vm.onChangeValue(_input2, value.key);
        }
      }
    })], 1) : _c('span', [_vm._v(" " + _vm._s(_vm._f("round")(value.friendlyValue, 2)) + " ")])]);
  }), _c('PropertyListItem', {
    staticClass: "d-flex align-items-center mb-2",
    attrs: {
      "title": "Validation Date",
      "border": !!_vm.editMode
    }
  }, [_vm.editMode && _vm.form.confirmedAt ? _c('FormItem', {
    attrs: {
      "id": "confirmedAt",
      "type": "text",
      "disabled": true
    },
    model: {
      value: _vm.form.confirmedAt,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "confirmedAt", $$v);
      },
      expression: "form.confirmedAt"
    }
  }) : _vm.form.confirmedAt ? _c('span', [_vm._v(" " + _vm._s(_vm.form.confirmedAt) + " ")]) : _c('span', [_vm._v(" N/A ")])], 1)], 2)]), _vm.invoice.type === 'waste' ? _c('div', [_c('SectionTitle', [_vm._v("Consumption")]), _vm.loadingActionConsumption.list ? _c('div', [_c('Spinner')], 1) : _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-xl-8"
  }, [_vm.editMode ? _c('table', {
    staticClass: "table table-borderless mb-4"
  }, [_c('tbody', _vm._l(_vm.wasteCategories, function (category, idx) {
    return _c('tr', {
      key: category.name
    }, [_c('td', {
      staticClass: "font-w600"
    }, [_vm._v(_vm._s(category.name))]), _c('td', [_c('div', {
      staticClass: "input-group"
    }, [_c('FormItem', {
      staticClass: "col-xl-3",
      attrs: {
        "id": "consumption-value-".concat(idx),
        "placeholder": " "
      },
      model: {
        value: _vm.wasteConsumption[category.name].value,
        callback: function callback($$v) {
          _vm.$set(_vm.wasteConsumption[category.name], "value", $$v);
        },
        expression: "wasteConsumption[category.name].value"
      }
    }), _c('FormItem', {
      staticClass: "col-xl-3",
      attrs: {
        "id": "consumption-unit-".concat(idx),
        "type": "select",
        "alt": "",
        "placeholder": "Select a unit",
        "options": [{
          label: 'kg',
          value: 'kg'
        }, {
          label: 'tonnes',
          value: 'tonnes'
        }]
      },
      model: {
        value: _vm.wasteConsumption[category.name].unit,
        callback: function callback($$v) {
          _vm.$set(_vm.wasteConsumption[category.name], "unit", $$v);
        },
        expression: "wasteConsumption[category.name].unit"
      }
    })], 1)])]);
  }), 0)]) : _c('div', _vm._l(Object.keys(_vm.wasteConsumption).filter(function (category) {
    return _vm.wasteConsumption[category].value > 0;
  }), function (category) {
    return _c('PropertyListItem', {
      key: category,
      staticClass: "d-flex align-items-center mb-2",
      attrs: {
        "title": category
      }
    }, [_vm._v(" " + _vm._s(_vm._f("numberFormat")(_vm.wasteConsumption[category].value, 2)) + " " + _vm._s(_vm.wasteConsumption[category].unit) + " ")]);
  }), 1)])])], 1) : _vm._e(), _c('div', {
    staticClass: "mt-3"
  }, [_c('div', {
    staticClass: "row no-gutters pb-2 mb-3 align-items-center"
  }, [_c('h3', {
    staticClass: "mb-0 pb-0"
  }, [_vm._v("Rates")]), _c('div', {
    staticClass: "text-right ml-auto"
  }, [_vm.editMode ? _c('button', {
    staticClass: "btn btn-primary ml-3",
    on: {
      "click": _vm.onClickAddRate
    }
  }, [_vm._v("Add Rate")]) : _vm._e()])]), _vm.editMode ? _c('p', [_vm._v("All the rates related to this invoice. Changing the consumption or cost of a rate will update the totals above.")]) : _vm._e(), !_vm.form.rates.length ? _c('div', {
    staticClass: "alert alert-warning"
  }, [_vm._v("This invoice currently has no rates listed")]) : _c('div', _vm._l(_vm.rateTypes, function (category) {
    var _vm$form$rates$filter;

    return _c('div', {
      key: category.value
    }, [(_vm$form$rates$filter = _vm.form.rates.filter(function (rate) {
      return rate.type === category.value;
    })) !== null && _vm$form$rates$filter !== void 0 && _vm$form$rates$filter.length ? _c('div', [_c('h5', [_vm._v(_vm._s(category.label))]), _c('InvoiceResultsRateTable', {
      attrs: {
        "rates": _vm.form.rates.filter(function (rate) {
          return rate.type === category.value;
        }),
        "edit-mode": _vm.editMode,
        "form": _vm.form.rates,
        "register-ids": _vm.filteredRegisterIds(_vm.account.registerIds),
        "unit-options": _vm.unitOptions
      },
      on: {
        "change": _vm.onChangeRate
      }
    })], 1) : _vm._e()]);
  }), 0)]), _vm.editMode || _vm.form.s3Key ? _c('div', [_c('SectionTitle', [_vm._v("Invoice File")]), _c('div', {
    staticClass: "row push"
  }, [_c('div', {
    staticClass: "d-flex flex-column col-auto"
  }, [_c('FileUpload', {
    attrs: {
      "loading": _vm.fileLoading,
      "existing-file-name": _vm.form.fileName,
      "read-only": !_vm.editMode,
      "error": _vm.validationErrors.upload
    },
    on: {
      "reset": _vm.onClearInvoiceFile
    },
    model: {
      value: _vm.form.file,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "file", $$v);
      },
      expression: "form.file"
    }
  })], 1)])], 1) : _vm._e(), _vm.editMode ? _c('div', [_c('button', {
    staticClass: "btn btn-primary mr-3",
    class: {
      disabled: _vm.isSavingChanges
    },
    on: {
      "click": _vm.onClickConfirmChanges
    }
  }, [_vm.isSavingChanges ? _c('span', [_c('i', {
    staticClass: "fa fa-circle-notch fa-spin mr-1"
  }), _vm._v(" Saving...")]) : _c('span', [_vm._v("Confirm Changes")])]), _c('button', {
    staticClass: "btn btn-alt-warning",
    class: {
      disabled: _vm.isSavingChanges
    },
    on: {
      "click": _vm.onClickCancel
    }
  }, [_vm._v("Undo Changes")])]) : _vm._e()]) : _c('div', {
    staticClass: "mt-3"
  }, [_c('div', {
    staticClass: "d-flex justify-content-between align-items-center mb-3"
  }, [_c('h3', {
    staticClass: "mb-2"
  }, [_vm._v(" Validation "), _vm.form.validation ? _c('span', {
    staticClass: "font-size-lg"
  }, [_c('span', {
    staticClass: "badge badge-danger mr-2"
  }, [_vm._v(_vm._s(_vm.form.validation.filter(function (result) {
    return result.type === 'danger';
  }).length) + " issues")]), _c('span', {
    staticClass: "badge badge-warning"
  }, [_vm._v(_vm._s(_vm.form.validation.filter(function (result) {
    return result.type === 'warning';
  }).length) + " warnings")])]) : _vm._e()]), _c('button', {
    staticClass: "btn btn-alt-info mr-3",
    on: {
      "click": _vm.onClickRevalidate
    }
  }, [_vm._m(1)])]), _c('div', [_c('div', [_c('div', {
    staticClass: "row"
  }, [_vm._m(2), _c('div', {
    staticClass: "col"
  }, [_vm.loadingAction.validate ? _c('Spinner') : !_vm.form.validation ? _c('div', {
    staticClass: "alert alert-warning"
  }, [_vm._v(" This invoice currently has no validation. Please click the refresh button to re-validate. ")]) : _vm._l(_vm.validationAlerts, function (item, index) {
    return _c('div', {
      key: index
    }, [_c('div', {
      staticClass: "alert",
      class: "alert-".concat(item.type),
      attrs: {
        "role": "alert"
      }
    }, [_c('p', {
      staticClass: "mb-0"
    }, [_c('i', {
      staticClass: "fa fa-fw mr-1",
      class: item.icon
    }), item.title ? _c('strong', [_vm._v(_vm._s(item.title))]) : _c('span', [_vm._v(_vm._s(item.message))])]), item.subTitle ? _c('div', [_c('strong', [_vm._v(_vm._s(item.subTitle))]), _vm._v(" " + _vm._s(item.message) + " "), _c('div', [_c('span', {
      staticClass: "badge",
      class: "badge-".concat(item.type)
    }, [_vm._v(_vm._s(item.rateType))])])]) : _vm._e()])]);
  })], 2)])])])])]) : _vm.loading.results ? _c('div', {
    staticClass: "d-flex justify-content-center py-5"
  }, [_vm._m(3)]) : _vm._e(), _c('ConfirmModal', {
    attrs: {
      "open": _vm.modals.edit,
      "title": "Edit Invoice",
      "text": "Please be aware that editing an invoice can affect reports and other data throughout Etainabl. If you have downloaded any reports which include this invoice/account/asset's data, you may need to re-download or re-export the report.<br/><strong>Note: Changes will not be re-validated against contracts and other invoices</strong>"
    },
    on: {
      "close": function close($event) {
        _vm.modals.edit = false;
      },
      "submit": _vm.onEdit
    }
  }), _c('ConfirmModal', {
    attrs: {
      "open": !!_vm.modals.addRate,
      "title": "Add Rate",
      "text": "Please enter/select a rate name",
      "lg-size": ""
    },
    on: {
      "close": function close($event) {
        _vm.modals.addRate = false;
      },
      "submit": _vm.onConfirmRateModal
    },
    model: {
      value: _vm.modals.addRate,
      callback: function callback($$v) {
        _vm.$set(_vm.modals, "addRate", $$v);
      },
      expression: "modals.addRate"
    }
  }, [_c('AddRateForm', {
    attrs: {
      "register-ids": _vm.filteredRegisterIds(_vm.account.registerIds),
      "unit-options": _vm.unitOptions
    },
    model: {
      value: _vm.modals.addRate,
      callback: function callback($$v) {
        _vm.$set(_vm.modals, "addRate", $$v);
      },
      expression: "modals.addRate"
    }
  })], 1)], 1);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "col"
  }, [_c('h3', {
    staticClass: "mb-0"
  }, [_vm._v("Invoice Details")])]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('span', [_c('i', {
    staticClass: "fa fa-fw fa-redo"
  }), _vm._v(" Refresh")]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "col-6"
  }, [_c('p', {
    staticClass: "mb-2"
  }, [_vm._v(" This invoice has been validated against a set of rules. Checks are made against related invoices, accounts and contracts. Anything that may be an issue or problem will be highlighted. For example, if the invoice dates overlap with another invoice on the same account. ")]), _c('p', {
    staticClass: "mb-2"
  }, [_vm._v("Click the refresh button to re-validate.")]), _c('p', [_c('strong', [_vm._v("Note:")]), _vm._v(" If you have made changes on the details tab, then you'll need to confirm those changes before re-validating to see the updated results. ")])]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "spinner-border spinner-lg text-secondary my-5",
    attrs: {
      "role": "status"
    }
  }, [_c('span', {
    staticClass: "sr-only"
  }, [_vm._v("Loading...")])]);
}]

export { render, staticRenderFns }